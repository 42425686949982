import React from "react";
import SearchIcon from "../../../assets/images/Search icon.png";
import "./LosTypeSearch.css";
function LosTypeSearch({ searchQuery, setSearchQuery, filteredServices }) {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <div className="los-search-container">
        <input
          type="text"
          placeholder="Search"
          className="los-search-input"
          style={{ margin: 0 }}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
        <button className="los-search-button">
          <img src={SearchIcon}></img>
        </button>
      </div>
      {!filteredServices.length && searchQuery && (
        <div className="no-results-tooltip">No results found</div>
      )}
    </div>
  );
}

export default LosTypeSearch;
