import React, { useEffect, useState } from "react";

import Card from "../../admincomponents/cluster-management/Card/Card";
import ClusterCard from "../../admincomponents/cluster-management/ClusterCard/ClusterCard";
import { GetData } from "../../api/api";
import SearchBox from "../../admincomponents/cluster-management/SearchBox/SearchBox";
import { useNavigate } from "react-router-dom";
import AddClusterBtn from "../../admincomponents/cluster-management/AddClusterBtn/AddClusterBtn";
import "./HomeAdmin.css";

function HomeAdmin({ data, setData }) {
  const [filteredData, setFilteredData] = useState([]);
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const Fetchdata = async () => {
      const res = await GetData();

      setData(res);
    };
    Fetchdata();
  }, []);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <>
      <div className="containera">
        <div className="card-container">
          <Card data={data} />
        </div>
        <SearchBox
          data={data}
          setFilteredData={setFilteredData}
          input={input}
          setInput={setInput}
        />
        <div
          onClick={() => {
            navigate("/61646d696e/homeAdmin/create-cluster");
          }}
        >
          <AddClusterBtn className="add-cluster-button" />
        </div>
        {filteredData.map((a) => (
          <div className="cluster-wrapper" key={a._id}>
            {a.clusters.map((c) => (
              <ClusterCard
                key={c.id}
                cluster={c}
                a={a._id}
                areaName={a.areaName}
                setData={setData}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

export default HomeAdmin;
