import React from "react";

import { Outlet, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import cross from "../assets/images/Cross Icon.png";
function AboutUsHeaderLayout() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100vw",
      }}
    >
      <div
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingInline: "18px",
          paddingBlock: "20px",
        }}
      >
        <img
          src={logo}
          style={{ width: "60px", height: "60px" }}
          onClick={() => {
            navigate("/");
          }}
        ></img>
        <img
          src={cross}
          style={{ width: "24px", height: "24px" }}
          onClick={() => {
            navigate(-1);
          }}
        ></img>
      </div>
      <Outlet></Outlet>
    </div>
  );
}

export default AboutUsHeaderLayout;
