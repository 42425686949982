import React from "react";
import "./Card.css";
function Card({ data }) {
  const getTotals = (areas) => {
    const totalAreas = areas.length;
    let totalClusters = 0;
    let totalBuildings = 0;
    let totalFlats = 0;

    areas.forEach((area) => {
      totalClusters += area.clusters.length;
      area.clusters.forEach((cluster) => {
        totalBuildings += cluster.buildings.length;
        cluster.buildings.forEach((building) => {
          totalFlats += building.numberOfFlats;
        });
      });
    });

    return { totalAreas, totalClusters, totalBuildings, totalFlats };
  };
  const totals = getTotals(data);
  const dataCount = [
    { name: "Clusters", count: totals.totalClusters },
    { name: "Areas", count: totals.totalAreas },
    { name: "Buildings", count: totals.totalBuildings },
    { name: "Apartments", count: totals.totalFlats },
  ];
  return (
    <div className="data-container">
      {dataCount.map((val) => (
        <div className="data-item" key={val.name}>
          <div className="data-name">{val.name}</div>
          <p className="data-count">{val.count}</p>
        </div>
      ))}
    </div>
  );
}

export default Card;
