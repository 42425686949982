import React from "react";
import "./ContactUs.css";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate=useNavigate();
  return (
    <section id="contactUs" className="contactUs">
      <div className="contactUs-content">
        <h2 className="ContactUs-h2">
          Innovative hyperlocal
          <span className="highlight"> chat based solution</span>
        </h2>
        <ul>
          <li>Connect with genuine people in your area on chat</li>
          <li>No haggling, no stress. Standard rates for all services</li>
          <li>Better Rates for all because Bulk Services in One Visit</li>
          <li>Genuine reviews from genuine neighbors</li>
        </ul>
        
          <button
            className="contact-button" onClick={()=>{navigate("/hero-service")}}
            style={{ display: "flex", gap: "5px", alignItems: "center" }}
          >
            <span>Top Services</span>
            
          </button>
        
      </div>
    </section>
  );
};

export default ContactUs;
