import React from 'react';
import './VendorFilterModal.css';

const VendorFilterModal = ({ closeModal, onRoleSelect, professions}) => {
  const handleRoleClick = (role) => {
    onRoleSelect(role);
    closeModal();
  };
  return (
    <div
      className="modal-overlay-serviceModal"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className="modal-content-serviceModal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <button className="close-icon" onClick={closeModal}>
            &#10005;
          </button>
        </div>
        <div className="modal-body">
          {professions.map((profession, index) => {
            return (
                <div key={`${profession.key}-${index}`}>
                  <div className="modal-item" onClick={() => handleRoleClick(profession.key)}>{profession.value}</div>
                  <hr />
                </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default VendorFilterModal;
