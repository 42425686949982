export const getShopStatus = (openTime, closeTime, closeOn) => {
  const currentTime = new Date();
  const currentHours = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();
  const currentDay = currentTime.getDay();

  const [openHours, openMinutes] = openTime.split(":").map(Number);
  const [closeHours, closeMinutes] = closeTime.split(":").map(Number);
  
  const closeDays = {
    "sunday": 0,
    "monday": 1,
    "tuesday": 2,
    "wednesday": 3,
    "thursday": 4,
    "friday": 5,
    "saturday": 6
  };

  const closeOnDays = closeOn.map(day => closeDays[day.trim().toLowerCase()]);

  if (closeOnDays.includes(currentDay)) {
    return "closed";
  }

  if (currentHours > openHours && currentHours < closeHours) {
    return "open";
  } else if (currentHours === openHours && currentMinutes >= openMinutes) {
    return "open";
  } else if (currentHours === closeHours && currentMinutes < closeMinutes) {
    return "open";
  } else {
    return "closed";
  }
};
