import React, { useState, useEffect } from "react";
import TimePicker from "react-time-picker";
import styles from "./CreateShop.css";
import { DeleteShop, GetAreaNames, GetClusterNames, SubmitShopData, UpdateShop } from "../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import Grocery from "../../assets/images/shopIcon/Grocery.svg"
import Stationery from "../../assets/images/shopIcon/Stationery.svg"
import Hardware from "../../assets/images/shopIcon/Hardware.svg"
import Electrical from "../../assets/images/shopIcon/Electrical.svg"
import Garment from "../../assets/images/shopIcon/Garment.svg"
import HomeAndKitchen from "../../assets/images/shopIcon/HomeAndkitchen.svg"
import Medical from "../../assets/images/shopIcon/Medical.svg"
import ElectronicsAndMobile from "../../assets/images/shopIcon/ElectronicsAndMobile.svg"

const ShopOwnerForm = ({ onSubmit }) => {
  const location = useLocation();
  const initialData = location.state?.shop || {};
  const [shopId, setShopId] = useState(initialData?.shopId || ""); // Check if shopId exists
  const [shopName, setShopName] = useState(initialData?.shopName || "");
  const [distance, setDistance] = useState(initialData?.distance || "");
  const [shopDescription, setDescription] = useState(initialData?.shopDescription || "");
  const [tags, setTags] = useState(initialData?.tags?.join(", ") || "");
  const [openTime, setOpenTime] = useState(initialData?.openTime || "09:00");
  const [closeTime, setCloseTime] = useState(initialData?.closeTime || "18:00");
  const [closeOn, setCloseOn] = useState(initialData?.closeOn || []);
  const [shopNumber, setPhoneNumber] = useState(initialData?.shopNumber || "");
  const [justDialLink, setJustDialLink] = useState(initialData?.shopURL?.justDial || "");
  const [directionLink, setDirectionLink] = useState(initialData?.directionLink || "");
  const [facebookLink, setFacebookLink] = useState(initialData?.shopURL?.facebook || "");
  const [googleLink, setGoogleLink] = useState(initialData?.shopURL?.googleLink || "");
  const [instagramLink, setInstagramLink] = useState(initialData?.shopURL?.instagram || "");
  const [otherLink, setOtherLink] = useState(initialData?.shopURL?.otherLink || "");
  const [areas, setAreas] = useState([]);
  const [areaId, setAreaId] = useState(initialData?.areaId || "");
  const [clusters, setClusters] = useState([]);
  const [clusterId, setClusterId] = useState(initialData?.clusterId || "");
  const [imageURL, setImageURl] = useState(initialData?.imageURL || "");


  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const navigate = useNavigate();
  const shopIcons = [
    { name: "Grocery", url: Grocery },
    { name: "Stationery", url: Stationery },
    { name: "Hardware", url: Hardware },
    { name: "Electrical", url: Electrical },
    { name: "Garment", url: Garment },
    { name: "Home and Kitchen", url: HomeAndKitchen },
    { name: "Medical", url: Medical },
    { name: "Electronics and Mobile", url: ElectronicsAndMobile },
  ];

  const handleCloseOnChange = (day) => {
    setCloseOn((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const getAreas = async () => {
    const data = await GetAreaNames();
    console.log("Fetched areas:", data);
    setAreas(data);
  };

  useEffect(() => {
    getAreas();
    if (areaId) {
      handleAreaChange({ target: { value: areaId } });
    }
  }, []);
  const handleImageChange = (e) => {
    const selectedImageURL = e.target.value;
    setImageURl(selectedImageURL);
  };


  const handleAreaChange = async (e) => {
    const selectedAreaId = e.target.value;
    setAreaId(selectedAreaId); // Set areaId instead of areaName
    const data = await GetClusterNames(selectedAreaId); // Pass the area ID
    setClusters(Array.isArray(data) ? data : []);
  };

  const handleClusterChange = (e) => {
    setClusterId(e.target.value); // Set clusterId correctly
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      areaId,
      clusterId,
      shop: {
        shopName,
        distance,
        shopDescription,
        tags: tags.split(",").map((tag) => tag.trim()),
        openTime,
        closeTime,
        closeOn,
        shopNumber,
        imageURL,
        shopURL: {
          googleLink,
          justDial: justDialLink,
          instagram: instagramLink,
          otherLink,
        },
      },
    };
    const formUpdateData = {
      areaId,
      clusterId,
      shopId,
      updatedShop: {
        shopName,
        distance,
        shopDescription,
        tags: tags.split(",").map((tag) => tag.trim()),
        openTime,
        closeTime,
        closeOn,
        shopNumber,
        imageURL,
        shopURL: {
          googleLink,
          justDial: justDialLink,
          instagram: instagramLink,
          otherLink,
        },
      },
    };

    try {
      if (shopId) {
        let result
        if (initialData.clusterId == clusterId && initialData.areaId == areaId) {
          result = await UpdateShop(formUpdateData);
        }
        else {
          result = await SubmitShopData(formData);
          result = await DeleteShop(initialData.areaId, initialData.clusterId, initialData.shopId);
        }
        console.log("Shop updated:", result);
        alert("Success! The shop data has been updated.");
      } else {

        const result = await SubmitShopData(formData);
        console.log("Shop created:", result);
        alert("Success! The shop data has been submitted.");
      }

      onSubmit(formData);
      // Reset form fields
      setShopName("");
      setDistance("");
      setDescription("");
      setTags("");
      setOpenTime("09:00");
      setCloseTime("18:00");
      setCloseOn([]);
      setPhoneNumber("");
      setJustDialLink("");
      setDirectionLink("");
      setFacebookLink("");
      setGoogleLink("");
      setInstagramLink("");
      setOtherLink("");
      setAreaId("");
      setClusters([]);
      setClusterId("");
      setShopId("");
      navigate("/61646d696e/shops");
      setImageURl("");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit}>
        <label>
          Area
          <select value={areaId} onChange={handleAreaChange}>
            <option value="">Select Area</option>
            {areas.map((area) => (
              <option key={area.areaId} value={area.areaId}>
                {area.areaName}
              </option>
            ))}
          </select>
        </label>
        {areaId && (
          <label>
            Cluster Name
            <select
              value={clusterId}
              onChange={handleClusterChange}
            >
              <option value="">Select Society Name</option>
              {clusters.map((cluster) => (
                <option key={cluster.clusterId} value={cluster.clusterId}>
                  {cluster.name}
                </option>
              ))}
            </select>
          </label>
        )}
        <label>
          Shop Icon
          <select value={imageURL} onChange={handleImageChange} required>
            <option value="">Select Shop Icon</option>
            {shopIcons.map((icon) => (
              <option key={icon.name} value={icon.url}>
                {icon.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Shop Name
          <input type="text" value={shopName} onChange={(e) => setShopName(e.target.value)} required />
        </label>
        <label>
          Distance
          <input type="text" value={distance} onChange={(e) => setDistance(e.target.value)} required />
        </label>
        <label>
          Description
          <textarea value={shopDescription} onChange={(e) => setDescription(e.target.value)} required />
        </label>
        <label>
          Tags (comma separated)
          <input type="text" value={tags} onChange={(e) => setTags(e.target.value)} required />
        </label>

        <label>
          Open Time
          <TimePicker
            onChange={setOpenTime}
            value={openTime}
            disableClock={false}
            format="hh:mm a"
            className={styles.timePicker}
          />
        </label>
        <label>
          Close Time
          <TimePicker
            onChange={setCloseTime}
            value={closeTime}
            disableClock={false}
            format="hh:mm a"
            className={styles.timePicker}
          />
        </label>
        <label>
          Close On
          <div className={styles.closeOnSelector}>
            {daysOfWeek.map((day) => (
              <div key={day}>
                <input
                  type="checkbox"
                  id={day}
                  checked={closeOn.includes(day)}
                  onChange={() => handleCloseOnChange(day)}
                />
                <label htmlFor={day}>{day}</label>
              </div>
            ))}
          </div>
        </label>
        <label>
          Phone Number
          <input
            type="text"
            value={shopNumber}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
              setPhoneNumber(numericValue);
            }}
            required
          />
        </label>
        <label>
          Google Link
          <input type="text" value={googleLink} onChange={(e) => setGoogleLink(e.target.value)} />
        </label>
        <label>
          Just Dial Link
          <input type="text" value={justDialLink} onChange={(e) => setJustDialLink(e.target.value)} />
        </label>
        <label>
          Instagram Link
          <input type="text" value={instagramLink} onChange={(e) => setInstagramLink(e.target.value)} />
        </label>

       FaceBook Link
        <input type="text" value={otherLink} onChange={(e) => setOtherLink(e.target.value)} />
        <button type="submit">{shopId ? "Update Shop" : "Submit Shop"}</button>
      </form>
    </div>
  );
};

export default ShopOwnerForm;
