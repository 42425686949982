import React, { useState } from "react";
import "./DiwaliScreen.css";
import DiwaliImage from "../../assets/images/Diwali Illustration box.svg";
import TopVendorBlackLists from "../../components/TopSectionVendorBlacklist/TopVendorBlacklist";
import { diwaliOrderSubmit } from "../../api/api";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import ImageModal from "../../components/ImageModal/ImageModal";
function Diwali2024() {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleShowImage = (image) => {
    console.log("Showing image:", image); // Check the image URL
    setModalImage(image);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setModalImage("");
  };

  const [products, setProducts] = useState([
    {
      id: 1,
      name: "iLaqa Subtle",
      imageUrl: "../../ilaqa-subtle.PNG",
      actualPrice: 6810,
      discountedPrice: 2380,
      quantity: 0,
      totalPrice: 0,
    },
    {
      id: 2,
      name: "iLaqa Simple",
      imageUrl: "../../ilaqa-simple.PNG",
      actualPrice: 9210,
      discountedPrice: 3200,
      quantity: 0,
      totalPrice: 0,
    },
    {
      id: 3,
      name: "iLaqa SweetPie (Kids Special)",
      imageUrl: "../../ilaqa-sweetpie.PNG",
      actualPrice: 10850,
      discountedPrice: 3700,
      quantity: 0,
      totalPrice: 0,
    },
    {
      id: 4,
      name: "iLaqa Splendid",
      imageUrl: "../../ilqa-splendid.PNG",
      actualPrice: 12500,
      discountedPrice: 4400,
      quantity: 0,
      totalPrice: 0,
    },
    {
      id: 5,
      name: "iLaqa Stellar",
      imageUrl: "../../ilaqa-stellar.PNG",
      actualPrice: 19000,
      discountedPrice: 6650,
      quantity: 0,
      totalPrice: 0,
    },
    {
      id: 6,
      name: "iLaqa Spectacular",
      imageUrl: "../../ilqa-spectacular.PNG",
      actualPrice: 20750,
      discountedPrice: 7250,
      quantity: 0,
      totalPrice: 0,
    },
    {
      id: 7,
      name: "iLaqa Sensation",
      imageUrl: "../../ilaqa-sensation.PNG",
      actualPrice: 22800,
      discountedPrice: 7999,
      quantity: 0,
      totalPrice: 0,
    },
  ]);

  const [orderId, setOrderId] = useState(null);

  const [isCheckout, setIsCheckout] = useState(false);
  const [shippingInfo, setShippingInfo] = useState({
    fullName: "",
    phone: "",
    email: "",
    referredPhone: "",
    address: "",
  });
  const handleMobileChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      const { name, value } = e.target;
      setShippingInfo({ ...shippingInfo, [name]: value });
    }
  };

  const handleQuantityChange = (id, newQuantity) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id
          ? {
              ...product,
              quantity: newQuantity,
              totalPrice: calculateTotalPrice(
                product.discountedPrice,
                newQuantity
              ), // Update totalPrice
            }
          : product
      )
    );
  };

  const handleFocus = (id) => {
    setProducts((prevProducts) =>
      prevProducts.map(
        (product) =>
          product.id === id ? { ...product, quantity: "" } : product // Clear quantity on focus
      )
    );
  };

  const handleBlur = (id, quantity) => {
    if (quantity === "") {
      handleQuantityChange(id, 0); // Reset to 0 if input is empty on blur
    }
  };

  const calculateTotalPrice = (discountedPrice, quantity) => {
    return discountedPrice * quantity;
  };

  const grandTotal = products.reduce((acc, product) => {
    return acc + calculateTotalPrice(product.discountedPrice, product.quantity);
  }, 0);

  const handleCheckout = () => {
    // Calculate total quantity of all products
    const totalQuantity = products.reduce(
      (acc, product) => acc + product.quantity,
      0
    );

    if (totalQuantity === 0) {
      alert(
        "You must select at least one product to proceed with the checkout."
      );
    } else {
      // Proceed to checkout only if totalQuantity is greater than 0
      setIsCheckout(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo({ ...shippingInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Filter products with quantity greater than 0
      const filteredProducts = products.filter(
        (product) => product.quantity > 0
      );

      const orderId = await diwaliOrderSubmit(
        shippingInfo.fullName,
        shippingInfo.phone,
        shippingInfo.email,
        shippingInfo.referredPhone,
        shippingInfo.address,
        filteredProducts, // Send only products with quantity > 0
        grandTotal
      );
      setOrderId(orderId); // Capture order ID
    } catch (error) {
      alert(error.message); // Show error message if API call fails
    }
    setShippingInfo({
      fullName: "",
      phone: "",
      email: "",
      referredPhone: "",
      address: "",
    });
  };

  const handleGoHome = () => {
    navigate("/"); // Assuming the home page is at the root path
  };

  const handleGoBack = () => {
    setIsCheckout(false);
    setOrderId(null); // Reset order ID
  };

  return (
    <div>
      <TopVendorBlackLists image={DiwaliImage} />
      {!isCheckout && (
        <h1 className="header-title-diwali-page"> List of Products </h1>
      )}
      {!isCheckout ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ overflow: "scroll" }}>
            <table className="product-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Actual Price</th>
                  <th>Early Bird 65% off</th>
                  <th>Quantity</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <tr key={product.id}>
                    <td>{index + 1}</td>
                    <td>
                      {product.name}
                      <a
                        href="#"
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          marginLeft: "5px",
                        }}
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default anchor behavior
                          handleShowImage(product.imageUrl); // Pass the correct image URL
                        }}
                      >
                        Click Here
                      </a>
                    </td>
                    <td>{product.actualPrice}</td>
                    <td>{product.discountedPrice}</td>
                    <td>
                      <input
                        type="number"
                        value={product.quantity}
                        min="0"
                        onFocus={() => handleFocus(product.id)}
                        onBlur={() => handleBlur(product.id, product.quantity)}
                        onChange={(e) =>
                          handleQuantityChange(
                            product.id,
                            Number(e.target.value)
                          )
                        }
                      />
                    </td>
                    <td>{product.totalPrice.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="5" style={{ textAlign: "right" }}>
                    <strong>Grand Total:</strong>
                  </td>
                  <td style={{ fontWeight: "700" }}>{grandTotal.toFixed(2)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          {modalVisible && (
            <ImageModal image={modalImage} onClose={handleCloseModal} />
          )}
          <button
            onClick={handleCheckout}
            className="diwali-checkout-button"
            style={{ marginBottom: "20px" }}
          >
            Checkout
          </button>
        </div>
      ) : orderId ? (
        <div style={{ paddingInline: "5px" }}>
          <h2 className="header-title-diwali-page" style={{ marginLeft: 0 }}>
            Order Submitted!
          </h2>
          <p style={{ fontSize: "14px", padding: "10px" }}>
            Your order with order id{" "}
            <span style={{ fontWeight: "700" }}>{orderId}</span> has been
            received. Our team will reach out to you regarding the payment and
            delivery details. Thank you!
          </p>
          <div
            style={{
              padding: "20px",
              display: "flex",

              alignItems: "center",
            }}
          >
            <button
              onClick={handleGoBack}
              style={{ paddingInline: "20px" }}
              className="diwali-checkout-button"
            >
              Go Back to Order Page
            </button>
            <button
              onClick={handleGoHome}
              className="button-spacing diwali-checkout-button"
              style={{ paddingInline: "20px" }}
            >
              Go to Home
            </button>
          </div>
        </div>
      ) : (
        <div>
          <h2 className="header-title-diwali-page" style={{ marginLeft: 0 }}>
            Order Summary
          </h2>
          <div style={{ paddingInline: "8px" }}>
            <table className="product-table-1">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {products
                  .filter((product) => product.quantity > 0) // Filter products with quantity > 0
                  .map((product) => (
                    <tr key={product.id}>
                      <td>{product.name}</td>
                      <td>{product.quantity}</td>
                      <td>
                        {calculateTotalPrice(
                          product.discountedPrice,
                          product.quantity
                        ).toFixed(2)}
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="2" style={{ textAlign: "right" }}>
                    <strong>Grand Total:</strong>
                  </td>
                  <td>{grandTotal.toFixed(2)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div style={{ paddingInline: "20px", paddingBlock: "10px" }}>
            <h3
              className="header-title-diwali-page"
              style={{ marginLeft: 0, marginBottom: "10px" }}
            >
              Shipping Address
            </h3>
            <form>
              <label>
                Full Name:
                <input
                  type="text"
                  name="fullName"
                  value={shippingInfo.fullName}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Phone Number:
                <input
                  type="text"
                  name="phone"
                  value={shippingInfo.phone}
                  onChange={handleMobileChange}
                  required
                />
              </label>
              <label>
                Email:
                <input
                  type="text"
                  name="email"
                  value={shippingInfo.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Referred Phone Number:
                <input
                  type="text"
                  name="referredPhone"
                  value={shippingInfo.referredPhone}
                  onChange={handleMobileChange}
                />
              </label>
              <label>
                Address:
                <textarea
                  name="address"
                  value={shippingInfo.address}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <div
                style={{
                  padding: "20px",
                  display: "flex",

                  alignItems: "center",
                }}
              >
                <button
                  onClick={handleSubmit}
                  className="diwali-checkout-button"
                >
                  Submit
                </button>
                <button
                  onClick={handleGoBack}
                  className="button-spacing diwali-checkout-button"
                  style={{ paddingInline: "20px" }}
                >
                  Go Back
                </button>{" "}
              </div>{" "}
            </form>{" "}
          </div>
        </div>
      )}
    </div>
  );
}

export default Diwali2024;
