import React, { useState } from "react";
import "./ReferralFormAdmin.css";
import { PostReferralDetails } from "../../api/api";

function ReferralFormAdmin() {
  const [formData, setFormData] = useState({
    referrerName: "",
    referrerPhone: "",
    refereePhone: "",
    serviceName: "",
    serviceCost: "",
    rewardAmount: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to the backend
    console.log("Form submitted: ", formData);
    await PostReferralDetails(
      formData.referrerName,
      formData.referrerPhone,
      formData.refereePhone,
      formData.serviceName,
      formData.serviceCost
    );
    setFormData({
      referrerName: "",
      referrerPhone: "",
      refereePhone: "",
      serviceName: "",
      serviceCost: "",
      rewardAmount: "",
    });
  };

  return (
    <div className="referral-form-container">
      <h2 className="referral-form-title">Referral Form</h2>
      <form onSubmit={handleSubmit} className="referral-form">
        <div className="referral-form-group">
          <label htmlFor="referrerName" className="referral-form-label">
            Referrer Name
          </label>
          <input
            type="text"
            id="referrerName"
            name="referrerName"
            value={formData.referrerName}
            onChange={handleChange}
            className="referral-form-input"
            required
          />
        </div>
        <div className="referral-form-group">
          <label htmlFor="referrerPhone" className="referral-form-label">
            Referrer Phone Number
          </label>
          <input
            placeholder="+91"
            type="tel"
            id="referrerPhone"
            name="referrerPhone"
            value={formData.referrerPhone}
            onChange={handleChange}
            className="referral-form-input"
            required
          />
        </div>
        <div className="referral-form-group">
          <label htmlFor="refereePhone" className="referral-form-label">
            Referree Phone Number
          </label>
          <input
            placeholder="+91"
            type="tel"
            id="refereePhone"
            name="refereePhone"
            value={formData.refereePhone}
            onChange={handleChange}
            className="referral-form-input"
            required
          />
        </div>
        <div className="referral-form-group">
          <label htmlFor="serviceName" className="referral-form-label">
            Service Name
          </label>
          <input
            type="text"
            id="serviceName"
            name="serviceName"
            value={formData.serviceName}
            onChange={handleChange}
            className="referral-form-input"
            required
          />
        </div>
        <div className="referral-form-group">
          <label htmlFor="serviceCost" className="referral-form-label">
            Service Cost
          </label>
          <input
            type="number"
            id="serviceCost"
            name="serviceCost"
            value={formData.serviceCost}
            onChange={handleChange}
            className="referral-form-input"
            required
          />
        </div>

        <div className="referral-form-group">
          <button type="submit" className="referral-form-button">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ReferralFormAdmin;
