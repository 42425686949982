import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import "./SearchBox.css";
function SearchBox({ data, setFilteredData, input, setInput }) {
  const handleSearchClick = () => {
    const filteredData = data
      .map((area) => ({
        ...area,
        clusters: area.clusters.filter((cluster) =>
          cluster.name.toLowerCase().includes(input.toLowerCase())
        ),
      }))
      .filter((area) => area.clusters.length > 0);

    setFilteredData(filteredData);
  };

  useEffect(() => {
    handleSearchClick();
  }, [input]);
  return (
    <div className="search-container-admin">
      <input
        className="search-input-admin"
        placeholder="search cluster"
        onChange={(e) => {
          setInput(e.target.value);
        }}
      />
      <BsSearch />
    </div>
  );
}

export default SearchBox;
