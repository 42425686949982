import Sofamakeoverandclean from "../../../assets/images/Sofamakeoverandclean.png";
import { Carousel } from "react-bootstrap";

import style from "../HeroServiceSliderScreen/HeroservicesSlider.module.css";
import Documentation from"../../../assets/images/Documentation.png";
import EnfieldService from "../../../assets/images/EnfieldService.png";
import InvisibleGrill from "../../../assets/images/InvisibleGrill.png";
import Laptopupgrade2 from "../../../assets/images/Laptopupgrade2.svg";


function HeroServiceSlider() {
  return (
    <section id="heroService">
    <Carousel indicators={true} interval={3000}>
      <Carousel.Item>
        <div  >
        <div className={style.cstSlider} >
          <div className={style.imageContainer
          }>
          
            <div className="position-relative h-100">
              <img
                src={Sofamakeoverandclean}
                alt="Sofa"
                className={style.cstImg}    
              />
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className={style.cstSlider} >
          <div className={style.imageContainer}>
            <div className="position-relative h-100">
              <img
                src={Documentation}
                alt="Image2"
                className={style.cstImg}
                
              />
            </div>
          </div>
        </div>
      </Carousel.Item>


      <Carousel.Item>
        <div className={style.cstSlider} 
         >
          <div className="image-container">
            <div className="position-relative h-100">
              <img
                src={EnfieldService}
                alt="Image3"
                className={style.cstImg}
              />
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className={style.cstSlider} >
          <div className={style.imageContainer}>
            <div className="position-relative h-100">
              <img
                src={Laptopupgrade2}
                alt="Image4"
                className={style.cstImg}
                
              />
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className={style.cstSlider} >
          <div className={style.imageContainer}>
            <div className="position-relative h-100">
              <img
                src={InvisibleGrill}
                alt="Image4"
                className={style.cstImg}
                
              />
            </div>
          </div>
        </div>
      </Carousel.Item>

    </Carousel>
  </section>
  );
}
export default HeroServiceSlider;
