import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AddVendor.css";
import { GetVendorBlacklists, PostVendor } from "../../api/api";

function AddVendor() {
  const navigate = useNavigate();
  // vendorName, vendorProfession, mobileNumber, reason;
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    vendorName: "",
    vendorProfession: "",
    mobileNumber: "",
    image: "",
    reason: "",
  });
  const handleChange = (e) => {
    //console.log(e.target.value);
    if (e.target.name === "image") {
      const file = e.target.files[0];
      if (file && file.size > 1 * 1024 * 1024) {
        setErrorMessage("*Image should be less than 2 MB.");
        setFormData({
          ...formData,
          image: null, // Reset image in form data
        });
      } else {
        setFormData({
          ...formData,
          image: file,
        });
        setErrorMessage("");
      }
    } else {
      setFormData({
        ...formData,
        [e.target?.name]: e.target.value,
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const vendorFormData = new FormData();
    for (const item in formData) {
      vendorFormData.append(item, formData[item]);
    }
    await PostVendor(vendorFormData);
    const fetchVendors = async () => {
      const res = await GetVendorBlacklists();
      // setBlacklistedVendors(res);
    };
    fetchVendors();
    console.log(formData);
    setFormData({
      vendorName: "",
      vendorProfession: "",
      mobileNumber: "+91",
      image: "",
      reason: "",
    });
    navigate("/61646d696e/homeAdmin/vendor-blacklist-admin");
  };

  return (
    <form onSubmit={handleSubmit} className="vendor-add-form-container">
      <div>
        <label className="vendor-add-label">Vendor Name</label>
        <input
          type="text"
          name="vendorName"
          value={formData.vendorName}
          onChange={handleChange}
          className="vendor-add-input"
        />
      </div>

      <div>
        <label className="vendor-add-label">Vendor Profession</label>
        <input
          type="text"
          name="vendorProfession"
          value={formData.vendorProfession}
          onChange={handleChange}
          className="vendor-add-input"
        />
      </div>
      <div>
        <label className="vendor-add-label">Vendor Ph No.</label>
        <input
          type="text"
          name="mobileNumber"
          placeholder={"+91"}
          value={formData.mobileNumber}
          onChange={handleChange}
          className="vendor-add-input"
        />
      </div>
      <div>
        <label className="vendor-add-label">Vendor Picture</label>
        <input
          type="file"
          name="image"
          onChange={handleChange}
          className="vendor-add-input"
        />
        <p style={{ color: "red", fontSize: "12px" }}>{errorMessage}</p>
      </div>
      <div>
        <label className="vendor-add-label">Vendor reason</label>
        <input
          type="text"
          name="reason"
          value={formData.reason}
          onChange={handleChange}
          className="vendor-add-input"
        />
      </div>

      <div className="vendor-add-button-container">
        <button type="submit" disabled={formData.vendorName === ""}>
          Submit
        </button>
        <button
          className="vendor-add-cancel-button"
          onClick={() => {
            navigate("/61646d696e/homeAdmin/vendor-blacklist-admin");
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default AddVendor;
