import React from "react";
import "./OurCeo.css";
import anur from "../../../assets/images/Anur.svg";
function OurCeo() {
  return (
    <div className="our-ceo">
      <div className="ceo">
        <h1 className="blue-heading">
          Meet
          <span className="orange-heading">
            {" "}
            our <br></br>
          </span>
          Founder & CEO
        </h1>
        <div className="ceo-image">
          <img src={anur}></img>
        </div>

        <p className="paras">
          Anur Ram Puniyani is the visionary behind iLaqa, starting this journey
          with a passion for bringing people closer together. As our Founder and
          CEO, Anur is the driving force behind our mission, sparking ideas and
          steering the ship with enthusiasm. His dedication to building a
          connected community has turned iLaqa from a local initiative into a
          thriving network of 3,000+ "neighbours." With Anur at the helm, iLaqa
          continues to grow and make a positive impact, one
          neighbourhood at a time.
        </p>
      </div>
      <div className="letter-ceo">
        <h1 className="blue-heading" style={{ marginBottom: "20px" }}>
          <span className="orange-heading">Letter from </span>
          our<br></br> Chief Executive<br></br> Officer
        </h1>
        <div
          className="journey-meaning-mission-para"
          style={{ paddingInline: "14px" }}
        >
          <p className="paras" style={{ marginBlock: "20px" }}>
            Dear iLaqa Neighbour,
          </p>
          <p className="paras">
            As I reflect on our journey, I feel immense pride and gratitude.
            What started as a simple idea to connect neighbours with trusted
            service providers has grown into a community of over 3,000, all
            working toward a better neighbourhood.
          </p>
          <p className="paras" style={{ marginTop: "20px" }}>
            At iLaqa, our mission is to create meaningful connections that
            strengthen our community. Your trust and support have made iLaqa
            more than just a platform—it’s a way of life
          </p>
          <p className="paras">
            Looking ahead, we’re excited about the future. Our team is committed
            to improving and expanding our services to better meet your needs,
            while staying true to our core values.
          </p>
          <p className="paras">
            Thank you for being an essential part of the iLaqa family. Your
            involvement drives us to continually grow and serve you better.
          </p>
          <p className="paras" style={{ marginTop: "40px", marginBottom: 0 }}>
            With sincere gratitude,
          </p>
          <p className="paras" style={{ marginBlock: 0 }}>
            Anur Ram Puniyani
          </p>
          <p className="paras" style={{ marginBlock: 0 }}>
            Chief Executive Officer
          </p>{" "}
          <p
            className="paras"
            style={{
              marginBlock: 0,
              marginBottom: "40px",
            }}
          >
            iLaqa
          </p>
        </div>
      </div>
    </div>
  );
}

export default OurCeo;
