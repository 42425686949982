// ImageModal.js
import React from "react";
import "./ImageModal.css";

const ImageModal = ({ image, onClose }) => {
  if (!image) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content-diwali"
        onClick={(e) => e.stopPropagation()}
      >
        <img src={image} alt="Product" className="modal-content-img" />
        <button
          onClick={onClose}
          className="diwali-close-button"
          style={{ marginTop: "20px" }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ImageModal; // Ensure this is a default export
