import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import "./Header.css";
import closeIcon from "../../assets/images/closeIcon.png";
import menuIcon from "../../assets/images/menuIcon.png";

import { NavLink, useNavigate } from "react-router-dom";

import diwaliToran from "../../assets/images/Untitled design (83).gif";
const Header = ({ openModal }) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = !isOpen ? "hidden" : "auto";
  };
  const closeMenu = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };
  const openCommingSoonModalModal = () => {
    setIsOpen(false);
    openModal();
  };
  const Login = () => {
    navigate("/authentication");
    setIsOpen(false);
  };
  return (
    <header>
      <div
        className="logo"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={logo} alt="iLaqa Logo" />
      </div>

      <div className="hamburger-container">
        <div className="hamburger" onClick={toggleMenu}>
          <img
            src={isOpen ? closeIcon : menuIcon}
            alt="Menu Icon"
            className={`${isOpen ? "close-icon" : "menu-icon"}`}
          />
        </div>
      </div>
      <nav
        className={`nav ${isOpen ? "open" : "close"}`}
        style={{ marginTop: isOpen ? "22%" : "0" }}
      >
        <ul>
          <li>
            <NavLink to="/" className="menu-item no-arrow" onClick={closeMenu}>
              <span className="text">Home</span>
            </NavLink>
          </li>
          <li onClick={openCommingSoonModalModal}>
            <a href="#" className="menu-item">
              <span style={{ color: "#0C60CA" }} className="text">
                My iLaqa
              </span>
            </a>
          </li>
          <li>
            <NavLink to="/services" className="menu-item" onClick={closeMenu}>
              <span className="text">Services</span>
            </NavLink>
          </li>
          <li style={{ position: "relative" }}>
            <NavLink to="/diwali2024" className="menu-item" onClick={closeMenu}>
              <span className="text gradient-text" style={{ color: "" }}>
                Diwali Sale
              </span>
              <img
                src={diwaliToran}
                style={{
                  width: "100px",
                  marginLeft: "40px",
                  position: "absolute",
                  top: "-15px",
                }}
              ></img>
            </NavLink>
          </li>
          <li>
            <NavLink to="/emergency" className="menu-item" onClick={closeMenu}>
              <span className="text">Emergency</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/shops" className="menu-item" onClick={closeMenu}>
              <span className="text">Shops Near You</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/vendor-blacklist"
              className="menu-item"
              onClick={closeMenu}
            >
              <span className="text">Vendor Blacklist</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/aboutus" className="menu-item">
              <span className="text">About Us</span>
            </NavLink>
          </li>
          <li className="contact-us">
            <a
              href="https://wa.me/+917032512444?text=Hello iLaqa"
              className="menu-item"
            >
              <span className="text" style={{ alignItems: "end" }}>
                Contact Us
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
