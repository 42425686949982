import React from "react";
import "./LosSubServiceCard.css";
function LosSubServiceCard({ subservice }) {
  return (
    <div className="los-subservice-card">
      <h2 className="subservice-name">{subservice.name}</h2>
      <div className="subservices">
        {subservice.details.map((details) => {
          return (
            <div className="subservice-detail">
              <p className="name">{details.name}</p>
              <p className="value">{details.value}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LosSubServiceCard;
