import React from 'react';
import './ShopFilterModal.css';

const ShopFilterModal = ({ closeModal, onAreaSelect, areas }) => {
  const handleAreaClick = (area) => {
    onAreaSelect(area); 
    closeModal();
  };
  console.log("areas" , areas)

  return (
    <div
      className="modal-overlay-shop"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className="modal-content-shop"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <button className="close-icon" onClick={closeModal}>
            &#10005;
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-item" onClick={() => handleAreaClick('')}>
            All Areas
          </div>
          <hr />
          {areas.map((area, i) => (
            <div key={i}>
              <div className="modal-item" onClick={() => handleAreaClick(area)}>
                {area}
              </div>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShopFilterModal;
