import React from "react";
import "./OurJourney.css";
import drRamPuniyani from "../../../assets/images/Ram Puniyani.svg";
import drSmithaPuniyani from "../../../assets/images/Smita Puniyani.svg";
function OurJourney() {
  return (
    <div className="our-journey-page">
      <div className="who-we-are">
        <h1 className="blue-heading" style={{ marginBottom: "20px" }}>
          Our <span className="orange-heading">Journey</span> So Far
        </h1>
        <p className="paras">
          iLaqa started in November 2022 as a passion project, connecting
          neighbors with trusted service providers. It quickly grew, and today,
          we're a registered company with over 3,000 users—our "neighbours." Our
          journey has been fueled by a shared belief in the power of community
          and the dedication of a growing, passionate team. As we continue to
          develop new ways to meet the evolving needs and trust of our
          neighbourhood, we remain committed to our original mission: to create
          a connected, supportive community where everyone can thrive together.
        </p>
      </div>
      <div className="journey-meaning-mission">
        <h1 className="blue-heading">
          "<span className="orange-heading">iLaqa</span> : The Meaning and
          Mission"
        </h1>
        <div className="journey-meaning-mission-para">
          {" "}
          <p className="paras">
            The term "iLaqa" in Hindi, translating to "area" or "territory,"
            carries with it a sense of place and belonging. It’s not just about
            a physical space, but also about the social and cultural bonds that
            define a community within that space.
          </p>
          <p className="paras">
            "iLaqa" symbolizes more than just a geographic area. It represents a
            shared environment where people come together, support each other,
            and build a collective identity. The idea is to cultivate a strong
            sense of community within this "territory". It’s about reclaiming
            the village-like camaraderie in modern urban settings, where the
            "iLaqa" becomes a safe, supportive space that everyone contributes
            to and benefits from.
          </p>
        </div>
      </div>
      <div className="key-supporters">
        <h1
          className="blue-heading"
          style={{ fontSize: "24px", textAlign: "center" }}
        >
          Our Key Supporters
        </h1>
        <div className="key-supporters-section">
          <div className="key-supporters-section-left">
            <img
              className="key-supporters-section-img"
              src={drRamPuniyani}
            ></img>
            <p className="blue-heading" style={{ fontSize: "12px" }}>
              <a
                style={{ textDecoration: "underline" }}
                href="https://en.m.wikipedia.org/wiki/Ram_Puniyani"
                target="_blank"
              >
                Dr. Ram Puniyani
              </a>
            </p>
          </div>
          <div className="key-supporters-section-right">
            <img
              className="key-supporters-section-img"
              src={drSmithaPuniyani}
            ></img>
            <p className="blue-heading" style={{ fontSize: "12px" }}>
              <a
                style={{ textDecoration: "underline" }}
                href="https://mumbai.ashanet.org/coordinators/"
                target="_blank"
              >
                Dr. (Mrs)Smita Puniyani
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurJourney;
