import React from 'react';
import './EmergencyFilterModal.css';

const EmergencyFilterModal = ({ closeModal, onAreaSelect , areas }) => {
    console.log("areas" , areas )
  const handleAreaClick = (area) => {
    onAreaSelect(area);
    closeModal();
  };

  return (
    <div
      className="modal-overlay-emergency"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className="modal-content-emergency"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <button className="close-icon" onClick={closeModal}>
            &#10005;
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-item" onClick={() => handleAreaClick('')}>All Areas</div>
          <hr />
          {areas.map((area, index) => (
            <React.Fragment key={index}>
              <div className="modal-item" onClick={() => handleAreaClick(area)}>
                {area}
              </div>
              <hr />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmergencyFilterModal;
