import { URL } from "../env";

export const GetAreaNames = async () => {
  const res = await fetch(`${URL}/areas`);
  const data = await res.json();

  return data;
};

export const GetBuildingNames = async (areaId) => {
  const res = await fetch(`${URL}/areas/${areaId}/buildings`);
  const data = await res.json();

  return data;
};
export const GetClusterNames = async (areaId) => {
  const res = await fetch(`${URL}/shops/${areaId}/clusters`);
  const data = await res.json();
  console.log("cluster data ", data);
  return data;
};

export const getShops = async () => {
  const res = await fetch(`${URL}/shops/get`);
  const data = await res.json();
  return data;
};

export const getShopsArea = async () => {
  const res = await fetch(`${URL}/shops/areas-with-shops`);
  const data = await res.json();
  return data;
};
export const GetServices = async () => {
  const res = await fetch(`${URL}/los/get`);
  const data = await res.json();
  return data;
};
export const GetWhatsappLink = async (area, building) => {
  const res = await fetch(
    `${URL}/areas/${area}/buildings/${building}/whatsapp-link`
  );
  const data = await res.json();

  return data;
};

export const PostUserDetails = async (
  name,
  phNum,
  area,
  building,
  location
) => {
  return await fetch(`${URL}/users`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: name,
      phone: phNum,
      areaName: area,
      buildingName: building,
      source: location,
    }),
  });
};

//admin
export const GetData = async () => {
  const res = await fetch(`${URL}/clusters/data`);
  const data = await res.json();

  return data;
};

export const GetClusters = async (areaName) => {
  const res = await fetch(`${URL}/clusters/${areaName}/clusters`);
  const data = await res.json();

  return data;
};
export const PostCluster = async (
  areaName,
  clusterName,
  buildings,
  whatsappLink,
  descri
) => {
  return await fetch(`${URL}/clusters/${areaName}/clusters`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: clusterName,
      description: descri,
      whatsappLinks: [whatsappLink],
      activeLinkIndex: 0,
      buildings: buildings,
    }),
  });
};
export const PostArea = async (
  areaName,
  clusterName,
  whatsappLink,
  buildings,
  descri
) => {
  return await fetch(`${URL}/clusters/area`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      areaName,
      cluster: {
        name: clusterName,
        description: descri,
        whatsappLinks: [whatsappLink],
        activeLinkIndex: 0,
        buildings,
      },
    }),
  });
};

export const PostEditedCluster = async (
  clusterId,
  areaName,
  newClusterName,
  whatsappLink,
  buildings,
  descri
) => {
  return await fetch(`${URL}/clusters/${areaName}/clusters/${clusterId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: newClusterName,
      description: descri,
      buildings: buildings,
      whatsappLink: whatsappLink,
    }),
  });
};
export const DeleteCluster = async (areaname, clusterid) => {
  const res = await fetch(`${URL}/clusters/${areaname}/clusters/${clusterid}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();

  return data;
};
export const SubmitShopData = async (formData) => {
  try {
    console.log("hi");
    const response = await fetch(`${URL}/shops/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error submitting form:", error);
    throw error;
  }
};
export const SubmitEmergencyData = async (formData, areaName) => {
  try {
    console.log("hi");
    const response = await fetch(
      `${URL}/emergencycontact/${areaName}/emergency-contacts`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error submitting form:", error);
    throw error;
  }
};

export const GetEmergencyData = async () => {
  const res = await fetch(`${URL}/emergencycontact/emergency-contacts`);
  return await res.json();
};
export const GetVendorBlacklists = async () => {
  const res = await fetch(`${URL}/blacklist/vendor`);
  return await res.json();
};
export const PostVendor = async (formData) => {
  return await fetch(`${URL}/blacklist`, {
    method: "POST",
    body: formData,
  });
};

export const DeleteVendor = async (vendorid) => {
  const res = await fetch(`${URL}/blacklist/${vendorid}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();

  return data;
};
export const UpdateEmergency = async (id, areaId, formUpdateData) => {
  try {
    const url = `${URL}/emergencycontact/${areaId}/emergency-contacts/${id}`;
    console.log("api, Url", url);
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formUpdateData),
    });

    if (!res.ok) {
      throw new Error(`Failed to update emergency contact: ${res.statusText}`);
    }

    const result = await res.json();
    return result; // Return the result of the update
  } catch (error) {
    console.error("Error in UpdateEmergency:", error);
    throw error; // Rethrow the error for further handling
  }
};

export const DeleteEmergency = async (areaId, emergencyId) => {
  const url = `${URL}/emergencycontact/${areaId}/emergency-contacts/${emergencyId}`;
  console.log("delete api url ", url);
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error in DeleteEmergency API:", error);
    throw error;
  }
};
export const UpdateShop = async (formUpdateData) => {
  try {
    const res = await fetch(`${URL}/shops/edit`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formUpdateData),
    });

    if (!res.ok) {
      throw new Error(`Failed to update shop: ${res.statusText}`);
    }

    const result = await res.json();
    return result;
  } catch (error) {
    console.error("Error in UpdateShop:", error);
    throw error;
  }
};

export const DeleteShop = async (areaId, clusterId, shopId) => {
  const url = `${URL}/shops/${areaId}/clusters/${clusterId}/shops/${shopId}`;
  console.log("delete shop api url ", url);
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error in DeleteShop API:", error);
    throw error;
  }
};

export const PostService = async (formData) => {
  return await fetch(`${URL}/los/add`, {
    method: "POST",

    body: formData,
  });
};

export const EditService = async (
  TypeId,
  serviceId,
  serviceName,
  description,
  subService
) => {
  return await fetch(`${URL}/los/${TypeId}/services/${serviceId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      serviceName,
      description,
      subService,
    }),
  });
};

export const DeleteService = async (typeId, serviceId) => {
  const res = await fetch(`${URL}/los/${typeId}/services/${serviceId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();

  return data;
};
export const GetServiceByType = async (type) => {
  const res = await fetch(`${URL}/los/type/${type}`);
  return await res.json();
};
export const GetServiceByName = async (name, type) => {
  const res = await fetch(`${URL}/los/type/${type}/service/${name}`);
  return await res.json();
};
export const PostReferralDetails = async (
  referrerName,
  referrerPhone,
  refereePhone,
  serviceName,
  serviceCost
) => {
  return await fetch(`${URL}/admin/add-referral`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      referrerName,
      referrerPhone,
      refereePhone,
      serviceName,
      serviceCost,
    }),
  });
};
export const PostTracking = async (areaName, societyname, location) => {
  return await fetch(`${URL}/sourcename/submit/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      areaName: areaName,
      societyName: societyname,
      source: location,
    }),
  });
};

export const diwaliOrderSubmit = async (
  fullName,
  phone,
  email,
  referredPhone,
  address,
  products,
  grandTotal
) => {
  try {
    const response = await fetch(`${URL}/diwali/order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName,
        phone,
        email,
        referredPhone,
        address,
        products,
        grandTotal,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to submit order. Please try again.");
    }

    const data = await response.json();
    return data.orderId; // Return the order ID
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error for handling in the calling function
  }
};
