import React, { useEffect, useState } from "react";
import "./EmergencyScreen.css"
import filterIcon from "../../assets/images/filterIcon.svg";
import EmergencyCard from "../../components/EmergencyContact/EmergencyCard/EmergencyCard";
import EmergencySearchBar from "../../components/EmergencyContact/EmergencySearchBar/EmergencySearchBar";
import EmergencyFilterModal from "../../components/EmergencyContact/EmergencyFilterModal/EmergencyFilterModal";
import TopVendorBlackLists from "../../components/TopSectionVendorBlacklist/TopVendorBlacklist";
import { GetEmergencyData } from "../../api/api";
import EmergencyPageIllutaryion from "../../assets/images/EmergencyIllutration.svg"
import GenralLoader from "../../components/Loader/GenralLoader";
import EmergencyModal from "../../components/EmergencyContact/EmergencyModal/EmergencyModal";

function EmergencyContacts() {
  const [searchQuery, setSearchQuery] = useState("");
  const [ismodal, setIsModal] = useState(false)
  const [emergencyData, setEmergencyData] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedAreaEmergency, setSelectedAreaEmergency] = useState([])
  const [selectedEmergency, setselectedEmergency] = useState([])
  const [uniqureArea, setuniqueArea] = useState([])
  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };
  const getEmergencyData = async () => {
    const data = await GetEmergencyData();
    setEmergencyData(data)
    setSelectedAreaEmergency(data)
    const uniqueAreas = [...new Set(data.map(item => item.area))];
    setuniqueArea(uniqueAreas)
    setLoading(false);

  };
  const handleEmergencyClick = (emergency) => {
    setselectedEmergency(emergency);
    setIsModal(true);
  };
  useEffect(() => {
    getEmergencyData()
  }, [])
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    console.log("query", query);
    setSearchQuery(query);

    const filtered = emergencyData.filter((v) =>
      v.contactName.toLowerCase().includes(query) ||
      v.type.toLowerCase().includes(query) ||
      (v.area && v.area.toLowerCase().includes(query)) ||
      (v.contactNumber && v.contactNumber.toLowerCase().includes(query))
    );

    setSelectedAreaEmergency(filtered);
  };
  const closeModal = () => {
    setIsModal(false);
  };
  console.log("uniqueAreas", uniqureArea)
  const handleAreaSelect = (area) => {
    if (area === "") {
      setSelectedAreaEmergency(emergencyData); // Reset to all emergencies if no area is selected
    } else {
      const filtered = emergencyData.filter(
        (v) => v.area.toLowerCase() === area.toLowerCase()
      );
      setSelectedAreaEmergency(filtered);
    }
  };
  return (
    <div>
      <TopVendorBlackLists image={EmergencyPageIllutaryion} />
      {ismodal && <EmergencyModal emergency={selectedEmergency} closeModal={closeModal} />}
      <div style={{ alignItems: "end", justifyContent: "end" }}>
        <EmergencySearchBar
          searchQuery={searchQuery}
          onSearch={handleSearch}
        />
      </div>
      {isFilterModalOpen && (
        <EmergencyFilterModal
          closeModal={closeFilterModal}
          areas={uniqureArea}
          onAreaSelect={handleAreaSelect}
        />
      )}
      <div className="vendor-header">
        <h1 className="header-title"> Emergency Contacts</h1>
        <img
          src={filterIcon}
          className="header-icon"
          onClick={() => setIsFilterModalOpen(true)}
          alt="Filter Icon"
        />
      </div>
      {loading ? (
        <GenralLoader />
      ) : (

        <div>
          {
            !selectedAreaEmergency.length ==0 ? (<EmergencyCard emergencies={selectedAreaEmergency} onEmergencyClick={handleEmergencyClick} />) : (
              <span className="no-emergency-text">
                no emergency
              </span>
            )
          }

        </div>
      )}


    </div>

  );
};
export default EmergencyContacts;
