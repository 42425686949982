import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import IlaqaLogo from "../assets/images/logo.png";

const PopupHandler = ({ isPopupVisible, setIsPopupVisible }) => {
  const location = useLocation();
  const isMobileDevice = () => window.innerWidth <= 932;

  useEffect(() => {
    if (!isMobileDevice() && !location.pathname.startsWith("/61646d696e")) {
      setIsPopupVisible(true);
      document.body.style.overflow = "hidden";
    } else {
      setIsPopupVisible(false);
      document.body.style.overflow = "auto";
    }
  }, [location]);

  if (!isPopupVisible) return null;

  return (
    <div id="popup-overlay" className={`popup-overlay ${isPopupVisible ? "show" : ""}`}>
      <div id="popup" className="popup">
        <img src={IlaqaLogo} alt="Company Logo" />
        <p>
          For the best experience,
          <br />
          please visit our site on a mobile device.
        </p>
      </div>
    </div>
  );
};

export default PopupHandler;
