import React, { useEffect, useState } from "react";
import "./LosSubServices.css";
import arrow from "../../assets/images/ArrowBAck.svg";
import { useNavigate, useParams } from "react-router-dom";
import LosSubServiceCard from "../../components/LOS/LosSubService/LosSubServiceCard";

import { URL } from "../../env";
import { GetServiceByName } from "../../api/api";

function LosSubServices() {
  const [services, setServices] = useState([]);
  const { service, servicetype } = useParams();

  const navigate = useNavigate();

  const handleBookNow = () => {
    window.location.href = `https://wa.me/+917032512444?text=Hello iLaqa, I want to book ${service.serviceName} service `;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await GetServiceByName(service, servicetype);
      setServices(data.services[0]);
    };
    fetchData();
  }, []);
  return (
    <div className="los-subservice">
      <div className="los-subservice-top">
        <button
          className="servicelist-backbtn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={arrow} alt="Back Arrow" />
        </button>
        <h1 className="servicelist-title los-subservice-to-h1">
          {services.serviceName}
        </h1>
      </div>
      <p className="los-subservice-top-descri">{services.description}</p>
      {services.image != null && (
        <img
          src={`${URL}/blacklist/image/${services.image}`}
          style={{ width: "100%", height: "auto", borderRadius: "20px" }}
        ></img>
      )}
      {services?.subService?.map((subservice) => {
        return <LosSubServiceCard subservice={subservice}></LosSubServiceCard>;
      })}

      <button className="los-book-now" onClick={handleBookNow}>
        Book Now
      </button>
    </div>
  );
}

export default LosSubServices;
