import React, { useEffect, useState } from "react";
import EmergencyClusterCard from "../../admincomponents/EmergencyCard/EmergencyCard";
import { GetEmergencyData } from "../../api/api";
import { useNavigate } from "react-router-dom";
import "./EmergencyHome.css";

function EmergencyHome() {
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const Fetchdata = async () => {
    console.log("Fetching emergency data");
    try {
      const res = await GetEmergencyData();
      setFilteredData(res);
      setIsLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error("Error fetching emergency data:", error);
      setIsLoading(false); // Set loading to false even if there's an error
    }
  };

  useEffect(() => {
    Fetchdata();
    setIsLoading(true);
  }, []);

  console.log("emergency data", filteredData);

  return (
    <>
      <div className="container-e">
        <button
          className="emergency-button add-emergency-cluster-button"
          onClick={() => navigate("/61646d696e/emergency/emergencyAdd")}
        >
          Add Emergency
        </button>
        <div className="card-container"></div>
        <div
          onClick={() => {
            navigate("/emergency/home/create-cluster");
          }}
        ></div>
        {isLoading ? (
          <p>Loading...</p> // Optional: You can add a loader or a spinner here
        ) : filteredData.length > 0 ? (
          filteredData.map((emergency) => (
            <div className="cluster-wrapper" key={emergency._id}>
              <EmergencyClusterCard
                key={emergency._id}
                emergency={emergency}
                onDelete={Fetchdata}
              />
            </div>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
    </>
  );
}

export default EmergencyHome;
