import React, { useState } from "react";
import closeIcon from "../../assets/images/Cancel.svg";
import phoneIcon from "../../assets/images/CallIcon.svg";
import "./VendorModal.css";
import {URL} from "../../env"
import ImageMaximise from "../ImageMaximise/ImageMaximise";

const VendorModal = ({ closeModal, vendor }) => {
  console.log("vendorList Items", vendor);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const handleImageClick = (imageUrl) => {
    setSelectedImageURL(`${URL}/blacklist/image/${imageUrl}`);
    setIsModalOpen(true);
  };
  return (
    <div
      className="modal-overlay-vendorModal"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className="modal-content-vendorModal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <img
            src={closeIcon}
            alt="Close"
            className="close-icon"
            onClick={closeModal}
          />
        </div>
        <div className="modal-body">
          <img
            src={
              vendor.imageUrl ? `${URL}/blacklist/image/${vendor.imageUrl}` : ""
            }
            alt="Vendor"
            className="selected-vendor-image"
            onClick={() => handleImageClick(vendor.imageUrl)}
          />
          <h2 className="vendor-name">{vendor.vendorName}</h2>
          <p className="vendor-profession">{vendor.vendorProfession}</p>
          <p className="vendor-contact">
            <img src={phoneIcon} alt="Call Icon" className="call-icon" />{" "}
            {vendor.mobileNumber}
          </p>
          <div className="reason-container">
            <strong style={{ color: "#0C60CA" }}>Reason:</strong>{" "}
            {vendor.reason}
          </div>
        </div>
      </div>
      {isModalOpen && (
            <ImageMaximise
              imageURL={selectedImageURL}
              closeModal={() => setIsModalOpen(false)}
            />
          )}
    </div>
  );
};

export default VendorModal;
