import React from "react";
import logo from "../../assets/images/logo.png";
import whatsappIcon from "../../assets/images/Whatsapp.png";
import character from "../../assets/images/Character.png";
import "./ComingSoon.css";

const ComingSoon = ({ closeModal, setIsLink }) => {
  return (
    <div
      className="modal-overlay-comingSoon"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className="modal-content-comingSoon"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="left">
          <img src={character} style={{ width: "100px" }}></img>
        </div>
        <div className="right">
          <img src={logo} className="logo-comingSoon "></img>
          <h1>
            We are<span> Coming Soon !</span>{" "}
          </h1>
          <p>
            We are currently working on this Page , Join you <span>iLaqa </span>
            to get notified
          </p>
          <button
            className="whatsapp-btn"
            onClick={() => {
              setIsLink(true);
              closeModal();
            }}
          >
            <img
              src={whatsappIcon}
              style={{ width: "10px", height: "10px" }}
            ></img>
            <span style={{ fontSize: "10px" }}> Join My iLaqa </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
