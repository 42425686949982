import React from "react";
import "./Help.css";
import whtsappIcon from "../../assets/images/Whatsapp.png";

import group2 from "../../assets/images/illutrationImage.svg";
import { useNavigate } from "react-router-dom";

const Help = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="group2-wrapper">
        <img className="group-2" src={group2}></img>
      </div>

      <section className="how-iLaqa-helps" style={{ paddingLeft: "10px" }}>
        <div className="left-section">
          <h2>
            Your <span>iLaqa</span> at your service
          </h2>
          <ul>
            <li>Connect easily to shops around you</li>
            <li>Connect to verified emergency contacts</li>
            <li>Vendor blacklist. List of vendors who misbehave or cheat</li>
          </ul>
          <div className="buttons">
            <a href="https://wa.me/+917032512444?text=Hello iLaqa">
              <button
                className="contact-button"
                style={{ display: "flex", gap: "5px", alignItems: "cen" }}
              >
                <span>Contact Us</span>
                <img src={whtsappIcon} width="20px" height="20px"></img>
              </button>
            </a>
            <button
              onClick={() => {
                console.log("hello");
                navigate("/vendor-blacklist");
              }}
              className="blacklist-button"
            >
              See Vendor Blacklist
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Help;
