import React from "react";
import "./ShopSearchBar.css";
import SearchIcon from "../../../assets/images/Search icon.png";
const ShopSearchBar = ({ searchQuery, onSearch }) => {
  const handleInputChange = (e) => {
    onSearch(e.target.value);
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <div className="los-search-container">
        <input
          type="text"
          placeholder="Search"
          className="los-search-input"
          style={{ margin: 0 }}
          value={searchQuery}
          onChange={handleInputChange}
        />
        <button className="los-search-button">
          <img src={SearchIcon}></img>
        </button>
      </div>
    </div>
  );
};

export default ShopSearchBar;
