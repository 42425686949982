import React, { useState } from "react";
import closeIcon from "../../../assets/images/Cancel.svg";
import phoneIcon from "../../../assets/images/CallIcon.svg";
import "./EmergencyModal.css";
import ImageMaximise from "../../ImageMaximise/ImageMaximise";

const EmergencyModal = ({ closeModal, emergency }) => {
  console.log("Emergency Contact Details", emergency);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const handleImageClick = (imageUrl) => {
    setSelectedImageURL(imageUrl);
    setIsModalOpen(true);
  };
  return (
    <div
      className="modal-overlay-emergencyModal"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className="modal-content-emergencyModal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <img
            src={closeIcon}
            alt="Close"
            className="close-icon"
            onClick={closeModal}
          />
        </div>
        <div className="modal-body">
          <img src={emergency.imageURL} alt="Contact" className="contact-image" onClick={() => handleImageClick(emergency.imageURL)} />
          <h2 className="contact-name">{emergency.contactName}</h2>
          <p className="contact-type">{emergency.type}</p>
          <p className="contact-distance">Distance: {emergency.distance}</p>
          <p className="contact-phone">
            <img src={phoneIcon} alt="Call Icon" className="call-icon" />{" "}
            {emergency.contactNumber}
          </p>
          <div className="description-container">
            <strong style={{ color: '#0C60CA' }}>Description:</strong> {emergency.contactDescription}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ImageMaximise
          imageURL={selectedImageURL}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default EmergencyModal;
