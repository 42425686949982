import React, { useEffect, useState } from "react";
import ShopCard from "../../admincomponents/ShopCard/ShopCard";
import { useNavigate } from "react-router-dom";
import { getShops } from "../../api/api";
import "./ShopHome.css";

function ShopHome() {
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const FetchData = async () => {
    console.log("Fetching shop data");
    try {
      const res = await getShops();
      setFilteredData(res);
      setIsLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error("Error fetching shop data:", error);
      setIsLoading(false); // Set loading to false even if there's an error
    }
  };

  useEffect(() => {
    FetchData();
    setIsLoading(true);
  }, []);

  console.log("shop data", filteredData);

  return (
    <>
      <div className="container-s">
        <button
          className="shop-button add-shop-cluster-button"
          onClick={() => navigate("/61646d696e/shops/shopAdd")}
        >
          Add Shop
        </button>
        <div className="card-container"></div>
        {isLoading ? (
          <p>Loading...</p> // Optional: You can add a loader or a spinner here
        ) : filteredData.length > 0 ? (
          filteredData.map((shop) => (
            <div className="cluster-wrapper" key={shop._id}>
              <ShopCard
                key={shop._id}
                shop={shop}
                onDelete={FetchData}
              />
            </div>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
    </>
  );
}

export default ShopHome;
