import React, { useEffect, useState } from "react";
import { GetAreaNames, GetData, PostArea, PostCluster } from "../../api/api";
import { useNavigate } from "react-router-dom";
import "./CreateCluster.css";

function CreateCluster({ setData }) {
  const navigate = useNavigate();
  const [areaNames, setAreaNames] = useState([]);
  const [building, setBuilding] = useState({ name: "", numberOfFlats: "" });
  const [formData, setFormData] = useState({
    areaType: "existing",
    areaName: "",
    clusterName: "",
    clusterDescri: "",
    buildings: [],
    whatsappLink: "",
  });
  useEffect(() => {
    const handleChangeAreaType = async () => {
      const data = await GetAreaNames();
      setAreaNames(data);
      console.log(data, "areas");
    };
    handleChangeAreaType();
  }, []);

  const handleChange = (e) => {
    //console.log(e.target.value);
    setFormData({
      ...formData,
      [e.target?.name]: e.target.value,
    });
  };

  const handleBuildingChange = (e) => {
    setBuilding({
      ...building,
      [e.target?.name]: e.target.value,
    });
  };

  const addBuilding = () => {
    setFormData({
      ...formData,
      buildings: [...formData.buildings, building],
    });
    setBuilding({ name: "", numberOfFlats: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission
    if (formData.areaType === "new") {
      PostArea(
        formData.areaName,
        formData.clusterName,
        formData.whatsappLink,

        formData.buildings,
        formData.clusterDescri
      );
    } else {
      PostCluster(
        formData.areaName,
        formData.clusterName,
        formData.buildings,
        formData.whatsappLink,
        formData.clusterDescri
      );
    }

    setFormData({
      areaType: "existing",
      areaName: "",
      clusterName: "",
      buildings: [],
      whatsappLink: "",
    });
    const Fetchdata = async () => {
      const res = await GetData();

      setData(res);
    };
    Fetchdata();
    navigate("/61646d696e/cluster-manage");
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <div>
        <label className="label">Area Type</label>
        <select
          name="areaType"
          value={formData.areaType}
          onChange={(e) => {
            handleChange(e);
          }}
          className="select"
          menuPosition="fixed"
        >
          <option value="existing" className="">
            Existing Area
          </option>
          <option value="new">New Area</option>
        </select>
      </div>
      {formData.areaType === "existing" ? (
        <div>
          <label className="label">Existing Area Name</label>
          <select
            name="areaName"
            value={formData.areaName}
            onChange={handleChange}
            className="select"
          >
            <option value="">Select Area</option>
            {areaNames.map((area) => (
              <option key={area.areaId} value={area.areaId}>
                {area.areaName}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div>
          <label className="label">New Area Name</label>
          <input
            type="text"
            name="areaName"
            value={formData.areaName}
            onChange={handleChange}
            className="input"
          />
        </div>
      )}
      <div>
        <label className="label">Cluster Name</label>
        <input
          type="text"
          name="clusterName"
          value={formData.clusterName}
          onChange={handleChange}
          className="input"
        />
      </div>
      <div>
        <label className="label">Cluster Description</label>
        <input
          type="text"
          name="clusterDescri"
          value={formData.clusterDescri}
          onChange={handleChange}
          className="input"
        />
      </div>
      <div className="flex-container">
        <div>
          <label className="label">Society Name</label>
          <input
            type="text"
            name="name"
            value={building?.name}
            onChange={handleBuildingChange}
            className="input"
          />
        </div>
        <div>
          <label className="label">Number of Flats</label>
          <input
            type="number"
            name="numberOfFlats"
            value={building.numberOfFlats}
            onChange={handleBuildingChange}
            className="input"
          />
        </div>
        <button
          type="button"
          onClick={addBuilding}
          className={`add-button ${
            building?.name === "" && building.numberOfFlats === ""
              ? "disabled-button"
              : ""
          }`}
        >
          Add
        </button>
      </div>
      <div>
        <label className="label">Societies</label>
        <ul>
          {formData.buildings.map((b, index) => (
            <li key={index}>
              {b?.name} - {b.numberOfFlats} flats
            </li>
          ))}
        </ul>
      </div>
      <div>
        <label className="label">WhatsApp Link</label>
        <input
          type="text"
          name="whatsappLink"
          value={formData.whatsappLink}
          onChange={handleChange}
          className="input"
        />
      </div>
      <div className="button-container">
        <button
          type="submit"
          disabled={formData.clusterName === ""}
          className={`submit-button ${
            formData.clusterName === "" ? "disabled-button" : ""
          }`}
        >
          Submit
        </button>
        <button
          className="cancel-button-cluster"
          onClick={() => {
            navigate("/61646d696e/cluster-manage");
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default CreateCluster;
