import React from "react";
import "./EmergencyCard.css";
import Call from "../../../assets/images/Call.png";

const EmergencyCard = ({ emergencies, onEmergencyClick }) => {
    console.log("Emergency details", emergencies);

    const groupedEmergencies = emergencies.reduce((acc, emergency) => {
        const { type } = emergency;
        if (!acc[type]) acc[type] = [];
        acc[type].push(emergency);
        return acc;
    }, {});

    return (
        <div className="emergency-card-container">
            {Object.keys(groupedEmergencies).map((type, index) => (
                <div key={index} className="emergency-type-group">
                    <h2 className="emergency-type-heading">{type}</h2>
                    <div className="emergency-row">
                        {groupedEmergencies[type].map((emergency, index) => (
                            <div
                                className={`card delay-${index}`}
                                key={emergency._id}
                                onClick={() => onEmergencyClick(emergency)}
                            >
                                <div className="emergency-image-container">
                                    <img src={emergency.imageURL} alt={emergency.contactName} className="emergency-image-card" />
                                </div>
                                <h3 className="emergency-name">{emergency.contactName}</h3>
                                <p className="emergency-role">{emergency.type}</p>
                                <p className="emergency-contact">
                                    <img src={Call} alt="Call Icon" className="call-icon" />{" "}
                                    {emergency.contactNumber}
                                </p>
                                <div>
                                    <p className="emergency-reason">
                                        <strong>HyperMarket:</strong>
                                        {emergency.contactDescription.length >15
                                        ? `${emergency.contactDescription.substring(0,15)}... ` :emergency.contactDescription}
                                        {emergency.contactDescription.length > 20 && (
                                            <a href="#">Read Full</a>
                                        )}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default EmergencyCard;
