import React from "react";
import logo from "../../assets/images/logo.png";
import "./Headeradmin.css";
import { useLocation, useNavigate } from "react-router-dom";
function HeaderAdmin() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div
      className="header-container"
      style={{
        width: "100vw",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingInline: "18px",
        paddingBlock: "20px",
      }}
    >
      <img
        src={logo}
        className="logoa"
        alt="Logo"
        onClick={() => {
          if (location.pathname === "/61646d696e") {
            navigate("/");
          }
        }}
      />
    </div>
  );
}

export default HeaderAdmin;
