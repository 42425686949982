import React from "react";
import "./Hero.css";
import Services from "../Services/Services";
import Modal from "../WhatsappLinkModal/Modal";
import { useNavigate } from "react-router-dom";
import Whatsapp from "../../assets/images/Whatsapp.png";

const Hero = ({ isLink, setIsLink }) => {
  const Navigate = useNavigate();
  return (
    <>
      <section className="hero">
        <div className="hero-text">
          <h1 className="ilaqa">iLaqa</h1>
          <h2 className="hero-heading">
            Your
            <span className="one-stop"> One stop</span> solution to all services
          </h2>
          <ul>
            <li>Verified and recommended vendors</li>
            <li>Talk to a real person for queries and booking </li>

            <li>Post service follow ups and refunds</li>
            <li>
              30+ services, documentation, laptop, mesh, grills, vehicle,cab
            </li>
          </ul>
        </div>
        <s>
          <Services />
        </s>

        <div
          className="parentdiv"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <div
            className="txtcolumn"
            style={{
              display: "flex",
              alignItems: "baseline",
              padding: "1rem 0rem 0rem",
            }}
          >
            <p
              style={{
                color: "#0C60CA",
                fontWeight: "bold",
                fontSize: "18px",
                flex: 2,
                paddingRight: "2.5rem",

                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              List of Services
            </p>
            <button
              onClick={() => {
                Navigate("/services");
              }}
              className="heroListButton"
              style={{
                padding: "4px 0px",
                flex: 1,
                margin: "0",
              }}
            >
              View
            </button>
          </div>

          <div
            className="buttoncolumn"
            style={{
              display: "flex",
              alignItems: "baseline",
              padding: "1rem 0rem",
            }}
          >
            <p
              style={{
                color: "#0C60CA",
                fontWeight: "bold",
                fontSize: "18px",
                flex: 2,
                width: "285px",
                paddingRight: "2rem",
              }}
            >
              Welcome to <span style={{ color: "orange" }}> your </span>iLaqa
            </p>

            <button
              onClick={() => {
                setIsLink(true);
              }}
              className="joinButton"
              style={{ padding: "6px 0px", flex: 1, margin: "0" }}
            >
              Join
              <img
                className="join-button-img"
                alt="joinbttn"
                style={{ width: "20px", height: "20px", marginLeft: "10px" }}
                src={Whatsapp}
              />
            </button>
          </div>
        </div>
      </section>

      {isLink && <Modal setIsLink={setIsLink} isLink={isLink}></Modal>}
    </>
  );
};

export default Hero;
