import React, { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { GetData, PostEditedCluster } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./EditCluster.css";
function EditCluster() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [buildings, setBildings] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [totalFlats, setTotalFlats] = useState(0);
  const [cluster, setcluster] = useState({});
  const [formData, setFormData] = useState({
    areaName: "",
    clusterName: "",
    numberOfFlats: "",
    whatsappLink: "",
    clusterDescri: "",
  });
  const [newBuilding, setNewBuilding] = useState({
    name: "",
    numberOfFlats: 0,
  });

  useEffect(() => {
    buildings?.map((a) => {
      setTotalFlats((prev) => prev + a.numberOfFlats);
    });
  }, [buildings]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleNewBuildingChange = (e) => {
    setNewBuilding({
      ...newBuilding,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    setcluster(state.cluster);
    console.log(state, "aaaaaa");
    setSelectedArea(state.a);
    setBildings(state.cluster.buildings);
  }, []);

  const addNewBuilding = () => {
    setBildings([
      ...buildings,
      {
        name: newBuilding?.name,
        numberOfFlats: Number(newBuilding.numberOfFlats),
      },
    ]);

    setNewBuilding({ name: "", numberOfFlats: 0 });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData.clusterDescri);
    PostEditedCluster(
      cluster._id,
      selectedArea,
      formData.clusterName,
      formData.whatsappLink,
      buildings,
      formData.clusterDescri
    );

    setFormData({
      areaName: "",
      clusterName: "",
      numberOfFlats: "",
      whatsappLink: "",
      clusterDescri: "",
    });

    navigate("/61646d696e/cluster-manage");
  };

  const deleteBuilding = (buildingId) => {
    const newBuildings = buildings.filter(
      (building) => building._id !== buildingId
    );
    setBildings(newBuildings);
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <>
        <div>
          <label className="label">Cluster Name</label>
          <input
            type="text"
            name="clusterName"
            placeholder={cluster?.name}
            value={formData.clusterName}
            onChange={handleChange}
            className="input"
          />
        </div>
        <div>
          <label className="label">Cluster Description</label>
          <input
            type="text"
            placeholder="Add new description"
            name="clusterDescri"
            value={formData.clusterDescri}
            onChange={handleChange}
            className="input"
          />
        </div>

        <div>
          <label className="label">WhatsApp Link</label>
          <input
            type="text"
            placeholder="Add active whatsapp link"
            name="whatsappLink"
            value={formData.whatsappLink}
            onChange={handleChange}
            className="input"
          />
        </div>

        <div>
          <h3 className="mt-4">Societies</h3>
          <h3 className="mt-4">Total Apartments: {totalFlats}</h3>
          {buildings?.map((building) => (
            <div key={building._id} className="building-container">
              <div>
                <label className="label">Society Name</label>
                <input type="text" value={building.name} className="input" />
              </div>
              <div>
                <label className="label">Number of Flats</label>
                <input
                  type="number"
                  value={building.numberOfFlats}
                  className="input"
                />
              </div>
              <button
                type="button"
                onClick={() => {
                  let res = window.confirm(
                    `Do you want to delete building.name?`
                  );
                  if (res) {
                    deleteBuilding(building._id);
                  }
                }}
                className="delete-button"
              >
                <MdDelete size={25} />
              </button>
            </div>
          ))}
        </div>
        <div className="flex-container">
          <div>
            <label className="label">New Society Name</label>
            <input
              type="text"
              name="name"
              value={newBuilding.name}
              onChange={handleNewBuildingChange}
              className="input"
            />
          </div>
          <div>
            <label className="label">Number of Flats for New Society</label>
            <input
              type="number"
              name="numberOfFlats"
              value={newBuilding.numberOfFlats}
              onChange={handleNewBuildingChange}
              className="input"
            />
          </div>
          <button type="button" onClick={addNewBuilding} className="add-button">
            Add New Building
          </button>
        </div>
      </>
      <div className="button-container">
        <button type="submit" className="submit-button">
          Submit
        </button>
        <button
          className="cancel-button-cluster"
          onClick={() => {
            navigate("/61646d696e/cluster-manage");
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default EditCluster;
