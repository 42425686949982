import React from "react";
import "./TopVendorBlacklist.css";
import Illustration from "../../../src/assets/images/Illustration.png";

const TopVendorBlackLists = ({image}) => {
  return (
    <>
      <section id="TopVendorBlacklist">
        <img src={image} alt="Illustration" />
      </section>
    </>
  );
};

export default TopVendorBlackLists;
