import React from "react";
import HeroServiceSlider from "../../components/HeroService/HeroServiceSliderScreen/HeroServiceSlider";
import HeroServiceCardSection from "../../components/HeroService/HeroServiceCardSection/HeroServiceCardSection";

function HeroService() {
  return (
    <div>
      <HeroServiceSlider />
      <HeroServiceCardSection />
    </div>
  );
}
export default HeroService;
