import React from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./AddClusterBtn.css";
function AddClusterBtn() {
  return (
    <button className="add-cluster-button">
      <FaPlus />
      <div>Add New Cluster</div>
    </button>
  );
}

export default AddClusterBtn;
