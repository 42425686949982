import React from "react";
import "./DiwaliOfferPopUp.css";
import close from "../../assets/images/DiwaliCloseIcon.svg";
import DiwaliGif from "../../assets/images/Untitled design (81).gif";
import { useNavigate } from "react-router-dom";
function DiwaliOfferPopUp({ setIsDiwaliPopUp }) {
  const navigate = useNavigate();
  return (
    <div
      className="diwali-popup-overlay"
      onClick={(e) => {
        setIsDiwaliPopUp(false);
        e.stopPropagation();
      }}
    >
      <div
        className="diwali-popup-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          className="diwali-popup-close-btn"
          onClick={() => {
            setIsDiwaliPopUp(false);
          }}
        >
          <img src={close} style={{ width: "27px", height: "27px" }}></img>
        </button>
        <img
          src={DiwaliGif}
          style={{ height: "350px", width: "auto", objectFit: "contain" }}
          alt="Diwali Offer"
          className="diwali-popup-img"
        />
        <div className="diwali-popup-text">
          Unbeatable wholesale prices,
          <br /> curated festive packages.
          <br /> Save big this Diwali with our <br /> Patakha deals!
        </div>
        <button
          className="diwali-popup-button"
          onClick={() => {
            navigate("/diwali2024");
          }}
        >
          Explore
        </button>
      </div>
    </div>
  );
}

export default DiwaliOfferPopUp;
