import React from "react";
import { useNavigate } from "react-router-dom";
import "./AdminDashBoard.css";

const AdminDashboard = () => {
  const navigate = useNavigate();

  return (
    <div className="AdminContainer">
      <button
        className="button"
        onClick={() => navigate("/61646d696e/cluster-manage")}
      >
        Cluster Management
      </button>
      <button className="button" onClick={() => navigate("/61646d696e/shops")}>
        Shop
      </button>
      <button
        className="button"
        onClick={() => navigate("/61646d696e/homeAdmin/vendor-blacklist-admin")}
      >
        Vendor
      </button>
      <button
        className="button"
        onClick={() => navigate("/61646d696e/emergency")}
      >
        Emergency
      </button>
      <button className="button" onClick={() => navigate("/61646d696e/los")}>
        List Of Services
      </button>
    </div>
  );
};

export default AdminDashboard;
