import React from "react";
import HeaderAdmin from "../admincomponents/Header/Headeradmin";
import { Outlet } from "react-router-dom";

function AdminLayout() {
  return (
    <>
      <HeaderAdmin></HeaderAdmin>
      <Outlet></Outlet>
    </>
  );
}

export default AdminLayout;
