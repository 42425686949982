import React, { useState, useEffect } from "react";
import styles from "./EmergencyAdd.css";
import { DeleteEmergency, GetAreaNames, SubmitEmergencyData, UpdateEmergency } from "../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import Ambulance from "../../assets/images/ambulance.svg"
import Medical from "../../assets/images/Medical.svg"
import WaterTanker from "../../assets/images/WaterTanker.svg"
import SnakeCatcher from "../../assets/images/SnakeCather.svg"
import KeyMaker from "../../assets/images/KeyMaker.svg"
import CarAndBikeJumpstart from "../../assets/images/CarAndBikeJumpStart.svg"
import CarTowing from "../../assets/images/CarToweing.svg"
import PunctureRepair from "../../assets/images/Puncture.svg"

const EmergencyContactForm = ({ onSubmit }) => {
    const location = useLocation();
    const initialData = location.state?.emergency || {};
    console.log("data for update", initialData)
    const [imageURL, setImageURL] = useState(initialData?.imageURL || "");
    const [contactName, setContactName] = useState(initialData?.contactName || "");
    const [distance, setDistance] = useState(initialData?.distance || "");
    const [contactNumber, setContactNumber] = useState(initialData?.contactNumber || "");
    const [contactDescription, setContactDescription] = useState(initialData?.contactDescription || "");
    const [areas, setAreas] = useState([]);
    const [areaName, setAreaName] = useState(initialData?.area || "");
    const [area, setArea] = useState(initialData?.area || "");
    const [type, setEmergencyType] = useState(initialData?.type || "");
    const navigate = useNavigate();

    const emergencyTypes = [
        "Medical",
        "Ambulance",
        "Water tanker",
        "Snake catcher",
        "Key maker",
        "Car and bike jumpstart",
        "Car towing",
        "Puncture repair"
    ];
    const emergencyImages = {
        "Medical": Medical,
        "Ambulance": Ambulance,
        "Water tanker": WaterTanker,
        "Snake catcher": SnakeCatcher,
        "Key maker": KeyMaker,
        "Car and bike jumpstart": CarAndBikeJumpstart,
        "Car towing": CarTowing,
        "Puncture repair": PunctureRepair
    };


    const getAreas = async () => {
        const data = await GetAreaNames();
        setAreas(data);
    };

    useEffect(() => {
        getAreas();
    }, []);

    const handleAreaChange = (e) => {
        setAreaName(e.target.value);
        setArea(e.target.value)
    };
    const handleEmergencyTypeChange = (e) => {
        const selectedType = e.target.value;
        setEmergencyType(selectedType);
        setImageURL(emergencyImages[selectedType] || "");
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            imageURL: imageURL || null,
            contactName,
            distance,
            contactNumber,
            contactDescription,
            type,
        };
        const formUpdateData = {
            imageURL: imageURL || null,
            contactName,
            distance,
            contactNumber,
            contactDescription,
            type,
            area
        };


        try {
            let result;
            if (initialData._id) {
                if (initialData.area == area) {
                    result = await UpdateEmergency(initialData._id, initialData.areaId, formUpdateData);
                }
                else {
                    result = await SubmitEmergencyData(formUpdateData, areaName);
                    result = await DeleteEmergency(initialData.areaId, initialData._id);
                }
            } else {
                // Create new emergency contact
                result = await SubmitEmergencyData(formData, areaName);
            }

            console.log(result);
            onSubmit(formData);
            alert('Success! The Emergency data has been submitted.');

            // Reset form only if it was a new submission
            if (!initialData) {
                setImageURL("");
                setContactName("");
                setDistance("");
                setContactNumber("");
                setContactDescription("");
                setAreaName("");
                setArea("");
            }
            navigate("/61646d696e/emergency");
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <div className={styles.formContainer}>
            <form onSubmit={handleSubmit}>
                <label>
                    Area
                    <select value={areaName} onChange={handleAreaChange} required>
                        <option value="">Select Area</option>
                        {areas.map((area) => (
                            <option key={area.areaName} value={area.areaName}>
                                {area.areaName}
                            </option>
                        ))}
                    </select>
                </label>
                <label>
                    Emergency Type
                    <select value={type} onChange={handleEmergencyTypeChange} required>
                        <option value="">Select Emergency Type</option>
                        {emergencyTypes.map((type, index) => (
                            <option key={index} value={type}>
                                {type}
                            </option>
                        ))}
                    </select>
                </label>
                {imageURL && <img src={imageURL} alt={type} className={styles.previewImage} />}
                <label>
                    Contact Name
                    <input
                        type="text"
                        value={contactName}
                        onChange={(e) => setContactName(e.target.value)}
                        required
                    />
                </label>
                <label>
                    Distance
                    <input
                        type="text"
                        value={distance}
                        onChange={(e) => setDistance(e.target.value)}
                        required
                    />
                </label>
                <label>
                    Phone Number
                    <input
                        type="text"
                        value={contactNumber}
                        onChange={(e) => {
                            const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                            setContactNumber(numericValue);
                        }}
                        required
                    />
                </label>

                <label>
                    Contact Description
                    <textarea
                        value={contactDescription}
                        onChange={(e) => setContactDescription(e.target.value)}
                        required
                    />
                </label>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default EmergencyContactForm;
