import React, { useState } from "react";
import "./ShopModal.css";
import closeIcon from "../../assets/images/Cancel.svg";
import Facebook from "../../assets/images/FB.png";
import Call from "../../assets/images/fluent_call_white.svg";
import GetDirection from "../../assets/images/googleLogo.svg";
import JustDial from "../../assets/images/JD.svg";
import openTime from "../../assets/images/Open.svg";
import closeTime from "../../assets/images/Close.svg";
import { getShopStatus } from "../ShopCard/GetShopStatus";
import instaLogo from "../../assets/images/instaLogo.svg";
import ImageMaximise from "../ImageMaximise/ImageMaximise";
const ShopModal = ({ shop, onClose, shopStatus }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const handleImageClick = (imageUrl) => {
    console.log("imageURl", imageUrl)
    setSelectedImageURL(imageUrl);
    setIsModalOpen(true);
  };
  console.log("shop data ", shop)
  if (!shop) return null;
  return (
    <div className="modal-overlay-shopModal">
      <div className="modal-content-shopModal">
        <div className="modal-header">
          <img
            src={closeIcon}
            alt="Close"
            className="shop-modal-close-icon"
            onClick={onClose}
          />
        </div>
        <div className="modal-body">
          <div className="shop-modal-white-space-container">
            <div className="round-image-container">
              <img src={shop.imageURL} alt="Shop" className="shop-image-modal" onClick={() => handleImageClick(shop.imageURL)} />
            </div>
            <img
              src={getShopStatus(shop.openTime, shop.closeTime, shop.closeOn) === "open" ? openTime : closeTime}
              alt={shopStatus}
              className="status-icon-modal"
            />
            <h2 className={shop.shopName.length >= 20 ? "modal-shop-name" : "modal-shop-name-lessthan"}>
              {shop.shopName}
            </h2>
          </div>
          <div className="reason-container">
            <div className="description-line">
              <strong style={{ color: "#4c9aff" }}>HyperMarket: </strong> { }{shop.shopDescription}
            </div>

            <div className="timing-line">
              <strong style={{ color: "#4c9aff" }}>Timing:</strong> {shop.openTime}am - {shop.closeTime}pm
              {shop.closeOn && shop.closeOn.length > 0 ? (
                <span> (Closed on {shop.closeOn.join(", ")})</span>
              ) : (
                <span> (Open every day)</span>
              )}
            </div>
            <div className="timing-line">
              <strong style={{ color: "#4c9aff" }}>Address:</strong> {shop.Address}
            </div>
            <div className="timing-line details-container">
              <div className="icon-container-logo">
                {shop.shopURL?.googleLink && (
                  <a
                    href={shop.shopURL.googleLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={GetDirection}
                      alt="Get Directions"
                      className="icon-logo"
                    />
                  </a>
                )}
                {shop.shopURL?.instagram && (
                  <a
                    href={shop.shopURL.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={instaLogo} alt="Instagram" className="icon-logo" />
                  </a>
                )}
                {shop.shopURL?.justDial && (
                  <a
                    href={shop.shopURL.justDial}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={JustDial} alt="JustDial" className="icon-logo" />
                  </a>
                )}
                {shop.shopURL?.otherLink && (
                  <a
                    href={shop.shopURL.otherLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Facebook} alt="Facebook" className="icon-logo" />
                  </a>
                )}
              </div>
            </div>
            <div className="shop-phone-container-modal">
              <p className="shop-phone">
                <a href={`tel:${shop.shopNumber}`} className="shop-phone-link">
                  <div className="phone-box">
                    <div className="call-icon-modal-container">
                      <img src={Call} alt="Call Icon" className="call-icon-modal" />
                    </div>
                    <span>Call Now</span>
                  </div>
                </a>
              </p>
            </div>
          </div>


        </div>

      </div>
      {isModalOpen && (
        <ImageMaximise
          imageURL={selectedImageURL}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ShopModal;
