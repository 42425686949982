import React from "react";
import closeIcon from "../../assets/images/Cancel.svg";
import "./ImageMaximise.css";

const ImageMaximise = ({ imageURL, closeModal }) => {
    console.log("image url " ,imageURL)
  return (
    <div
      className="image-maximise-overlay"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className="image-maximise-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="image-maximise-header">
          <img
            src={closeIcon}
            alt="Close"
            className="image-maximise-close-icon"
            onClick={closeModal}
          />
        </div>
        <div className="image-maximise-body">
          <img src={imageURL} alt="Maximized" className="image-maximise-img" />
        </div>
      </div>
    </div>
  );
};

export default ImageMaximise;
