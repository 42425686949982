import React, { useEffect, useRef, useState } from "react";
import "./Services.css";
import Fun from "../../assets/images/2.png";
import CarPoll from "../../assets/images/4.png";
import Chef from "../../assets/images/3.png";
import Repair from "../../assets/images/1.png";
import Diwali from "../../assets/images/Illustration Home page.svg";
import { GetServices } from "../../api/api";
import { useNavigate } from "react-router-dom";
import rightAr from "../../assets/images/Scroll right.svg";
import leftAr from "../../assets/images/Scroll left.svg";
const servicesTemp = [
  { id: 1, title: "Home Repair & Service", img: Repair },
  { id: 2, title: "Vehicles Repair & Services", img: Fun },
  { id: 3, title: "Events & Catering Services", img: Chef },
  { id: 4, title: "Documentations", img: CarPoll },
];

const Services = () => {
  const [services, setServices] = useState([]);
  const [arrow, setArrow] = useState("right");
  const navigate = useNavigate();
  const scrollRef = useRef();
  useEffect(() => {
    const cards = document.querySelectorAll(".service-card");
    cards.forEach((card, index) => {
      card.style.animationDelay = `${index * 0.5}s`;
    });
  }, []);
  useEffect(() => {
    const getServiceData = async () => {
      const res = await GetServices();

      setServices(res);
    };
    getServiceData();
  }, []);
  const ScrollLeft = () => {
    // scrollRef.current.scrollLeft -= 200;
    scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });
    setArrow("right");
  };
  const ScrollRight = () => {
    // scrollRef.current.scrollLeft += 200;
    scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });
    setArrow("left");
  };
  return (
    <section className="services">
      <div className="service-cards-container" style={{ position: "relative" }}>
        <div className="service-cards" ref={scrollRef}>
          <div className="service-card" onClick={() => navigate(`/diwali2024`)}>
            <img src={Diwali} alt={"Diwali"} />
            <div className="containerh2">
              <h3>iLaqa Diwali Dhamaka</h3>
            </div>
          </div>
          {services.map((service, i) => (
            <div
              key={service.id}
              className="service-card"
              onClick={() =>
                navigate(`/services/${service.type}`, {
                  state: service,
                })
              }
            >
              <img src={servicesTemp[i]?.img} alt={servicesTemp[i]?.title} />
              <div className="containerh2">
                <h3>{servicesTemp[i]?.title}</h3>
              </div>
            </div>
          ))}
        </div>
        {arrow === "left" && (
          <img
            src={rightAr}
            onClick={ScrollLeft}
            style={{ position: "absolute", left: "0px", top: "15px" }}
          ></img>
        )}
        {arrow === "right" && (
          <img
            src={leftAr}
            onClick={ScrollRight}
            style={{ position: "absolute", right: "0px", top: "15px" }}
          ></img>
        )}
      </div>
    </section>
  );
};

export default Services;
